/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { Booking } from '../model';
// @ts-ignore
import { BookingConfig } from '../model';
// @ts-ignore
import { BookingListing } from '../model';
// @ts-ignore
import { PaginatedBookingSMSHistoryList } from '../model';
// @ts-ignore
import { PatchedBooking } from '../model';
// @ts-ignore
import { ValidationError } from '../model';
/**
 * OrganisationBookingApi - axios parameter creator
 * @export
 */
export const OrganisationBookingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This can only occur when:Booking.status `CANCELLED`or Booking.status == `OPEN` AND There\'s no `ACCEPTED` Confirmations.  If there\'s `ACCEPTED` Confirmations, the Booking must be `CANCELLED` first.  See the `status` field on the Booking for more information.
         * @summary Archive the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingArchiveCreate: async (bookingId: string, buyerOrgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('orgBookingArchiveCreate', 'bookingId', bookingId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingArchiveCreate', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/{booking_id}/archive`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * An SMS is sent out to the recipient of the Confirmations alerting them it has been cancelled.  A cancellation can only occur when Booking.status == `OPEN` and it has at least one `ACCEPTED` Confirmation.  See the `status` field on the Booking for more information.
         * @summary Cancels the Booking and any `ACCEPTED` Confirmations with it
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingCancelCreate: async (bookingId: string, buyerOrgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('orgBookingCancelCreate', 'bookingId', bookingId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingCancelCreate', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/{booking_id}/cancel`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Outputs the configuration for the Bookings frontend
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfigRetrieve: async (buyerOrgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingConfigRetrieve', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/config`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Booking
         * @param {string} buyerOrgId 
         * @param {Booking} booking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingCreate: async (buyerOrgId: string, booking: Booking, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'booking' is not null or undefined
            assertParamExists('orgBookingCreate', 'booking', booking)
            const localVarPath = `/v2/org/{buyer_org_id}/booking`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(booking, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The filters on this endpoint behave exactly the same as the listing
         * @summary Generates a CSV of all the Bookings which match the filter criteria.
         * @param {string} buyerOrgId 
         * @param {'CT' | 'SH' | 'GT'} product product
         * @param {string} [dateFrom] date_from
         * @param {string} [dateTo] date_to
         * @param {'bin' | 'json'} [format] 
         * @param {string} [includeArchived] include_archived
         * @param {string} [includeEmpty] include_empty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingCsvCreate: async (buyerOrgId: string, product: 'CT' | 'SH' | 'GT', dateFrom?: string, dateTo?: string, format?: 'bin' | 'json', includeArchived?: string, includeEmpty?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingCsvCreate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'product' is not null or undefined
            assertParamExists('orgBookingCsvCreate', 'product', product)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/csv`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['date_from'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['date_to'] = dateTo;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (includeArchived !== undefined) {
                localVarQueryParameter['include_archived'] = includeArchived;
            }

            if (includeEmpty !== undefined) {
                localVarQueryParameter['include_empty'] = includeEmpty;
            }

            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingDestroy: async (bookingId: string, buyerOrgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('orgBookingDestroy', 'bookingId', bookingId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingDestroy', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/{booking_id}`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This listing differs from most in that it is a dict instead of a list, where each booking is grouped together by their date.  ``` {     \"results\": {         \"2022-01-02\": {             \"counts\": {\"incoming\": 10, \"outgoing\": 500\"},             \"bookings\": [{booking1}, {booking2}],         },         \"2022-01-01\": {             \"counts\": {\"incoming\": 0, \"outgoing\": 10\"},             \"bookings\": [{booking3}],         }     }     \"report\": {} } ```
         * @summary Retrieve a list of all the Booking associated with the Organisation
         * @param {string} buyerOrgId 
         * @param {'CT' | 'SH' | 'GT'} product product
         * @param {string} [dateFrom] date_from
         * @param {string} [dateTo] date_to
         * @param {string} [includeArchived] include_archived
         * @param {string} [includeEmpty] include_empty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingList: async (buyerOrgId: string, product: 'CT' | 'SH' | 'GT', dateFrom?: string, dateTo?: string, includeArchived?: string, includeEmpty?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingList', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'product' is not null or undefined
            assertParamExists('orgBookingList', 'product', product)
            const localVarPath = `/v2/org/{buyer_org_id}/booking`
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['date_from'] = dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['date_to'] = dateTo;
            }

            if (includeArchived !== undefined) {
                localVarQueryParameter['include_archived'] = includeArchived;
            }

            if (includeEmpty !== undefined) {
                localVarQueryParameter['include_empty'] = includeEmpty;
            }

            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Locking can only occur when the status of the Booking == OPEN and the AuthenticatedUser is an admin  See the `status` field on the Booking for more information.
         * @summary Lock the Booking so only Admins can edit it. Admin access only
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingLockCreate: async (bookingId: string, buyerOrgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('orgBookingLockCreate', 'bookingId', bookingId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingLockCreate', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/{booking_id}/lock`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unlocking can only occur when the status of the Booking == `LOCKED` and the Authenticated User is an                 admin\"  See the `status` field on the Booking for more information.
         * @summary Unlock the Booking. Admin access only
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingLockDestroy: async (bookingId: string, buyerOrgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('orgBookingLockDestroy', 'bookingId', bookingId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingLockDestroy', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/{booking_id}/lock`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the fields passed through and returns the newly updated Booking. See \"update\" for more info
         * @summary Partially update the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {PatchedBooking} [patchedBooking] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingPartialUpdate: async (bookingId: string, buyerOrgId: string, patchedBooking?: PatchedBooking, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('orgBookingPartialUpdate', 'bookingId', bookingId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingPartialUpdate', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/{booking_id}`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedBooking, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the details of a single Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingRetrieve: async (bookingId: string, buyerOrgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('orgBookingRetrieve', 'bookingId', bookingId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingRetrieve', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/{booking_id}`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of SMS\'s that have been sent from the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {string} [contact] contact
         * @param {string} [creator] creator
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {'CONFIRMATION' | 'REMINDER' | 'UPDATE'} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingSmsHistoryList: async (bookingId: string, buyerOrgId: string, contact?: string, creator?: string, page?: number, pageSize?: number, type?: 'CONFIRMATION' | 'REMINDER' | 'UPDATE', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('orgBookingSmsHistoryList', 'bookingId', bookingId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingSmsHistoryList', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/{booking_id}/sms/history`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (contact !== undefined) {
                localVarQueryParameter['contact'] = contact;
            }

            if (creator !== undefined) {
                localVarQueryParameter['creator'] = creator;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the Booking, returning the latest serialized data.  NOTE: the \'row\' still behaves the same as a `PUT` where by any fields not overwritten will still be present. This gives the system the ability to deactivate and re-active fields without losing data.  A Booking can only be edited in the following cases.  1. The Booking.status == `OPEN` 2. The Booking.status == `LOCKED` and Authenticated User is an admin.  If permission is denied the reason will be present in the `detail` key in the body.  See the `status` field on the Booking for more information. 
         * @summary Update the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {Booking} booking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingUpdate: async (bookingId: string, buyerOrgId: string, booking: Booking, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('orgBookingUpdate', 'bookingId', bookingId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingUpdate', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'booking' is not null or undefined
            assertParamExists('orgBookingUpdate', 'booking', booking)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/{booking_id}`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(booking, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationBookingApi - functional programming interface
 * @export
 */
export const OrganisationBookingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationBookingApiAxiosParamCreator(configuration)
    return {
        /**
         * This can only occur when:Booking.status `CANCELLED`or Booking.status == `OPEN` AND There\'s no `ACCEPTED` Confirmations.  If there\'s `ACCEPTED` Confirmations, the Booking must be `CANCELLED` first.  See the `status` field on the Booking for more information.
         * @summary Archive the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingArchiveCreate(bookingId: string, buyerOrgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingArchiveCreate(bookingId, buyerOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * An SMS is sent out to the recipient of the Confirmations alerting them it has been cancelled.  A cancellation can only occur when Booking.status == `OPEN` and it has at least one `ACCEPTED` Confirmation.  See the `status` field on the Booking for more information.
         * @summary Cancels the Booking and any `ACCEPTED` Confirmations with it
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingCancelCreate(bookingId: string, buyerOrgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingCancelCreate(bookingId, buyerOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Outputs the configuration for the Bookings frontend
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingConfigRetrieve(buyerOrgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingConfigRetrieve(buyerOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a Booking
         * @param {string} buyerOrgId 
         * @param {Booking} booking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingCreate(buyerOrgId: string, booking: Booking, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingCreate(buyerOrgId, booking, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The filters on this endpoint behave exactly the same as the listing
         * @summary Generates a CSV of all the Bookings which match the filter criteria.
         * @param {string} buyerOrgId 
         * @param {'CT' | 'SH' | 'GT'} product product
         * @param {string} [dateFrom] date_from
         * @param {string} [dateTo] date_to
         * @param {'bin' | 'json'} [format] 
         * @param {string} [includeArchived] include_archived
         * @param {string} [includeEmpty] include_empty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingCsvCreate(buyerOrgId: string, product: 'CT' | 'SH' | 'GT', dateFrom?: string, dateTo?: string, format?: 'bin' | 'json', includeArchived?: string, includeEmpty?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingCsvCreate(buyerOrgId, product, dateFrom, dateTo, format, includeArchived, includeEmpty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingDestroy(bookingId: string, buyerOrgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingDestroy(bookingId, buyerOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This listing differs from most in that it is a dict instead of a list, where each booking is grouped together by their date.  ``` {     \"results\": {         \"2022-01-02\": {             \"counts\": {\"incoming\": 10, \"outgoing\": 500\"},             \"bookings\": [{booking1}, {booking2}],         },         \"2022-01-01\": {             \"counts\": {\"incoming\": 0, \"outgoing\": 10\"},             \"bookings\": [{booking3}],         }     }     \"report\": {} } ```
         * @summary Retrieve a list of all the Booking associated with the Organisation
         * @param {string} buyerOrgId 
         * @param {'CT' | 'SH' | 'GT'} product product
         * @param {string} [dateFrom] date_from
         * @param {string} [dateTo] date_to
         * @param {string} [includeArchived] include_archived
         * @param {string} [includeEmpty] include_empty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingList(buyerOrgId: string, product: 'CT' | 'SH' | 'GT', dateFrom?: string, dateTo?: string, includeArchived?: string, includeEmpty?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingListing>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingList(buyerOrgId, product, dateFrom, dateTo, includeArchived, includeEmpty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Locking can only occur when the status of the Booking == OPEN and the AuthenticatedUser is an admin  See the `status` field on the Booking for more information.
         * @summary Lock the Booking so only Admins can edit it. Admin access only
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingLockCreate(bookingId: string, buyerOrgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingLockCreate(bookingId, buyerOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Unlocking can only occur when the status of the Booking == `LOCKED` and the Authenticated User is an                 admin\"  See the `status` field on the Booking for more information.
         * @summary Unlock the Booking. Admin access only
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingLockDestroy(bookingId: string, buyerOrgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingLockDestroy(bookingId, buyerOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the fields passed through and returns the newly updated Booking. See \"update\" for more info
         * @summary Partially update the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {PatchedBooking} [patchedBooking] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingPartialUpdate(bookingId: string, buyerOrgId: string, patchedBooking?: PatchedBooking, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingPartialUpdate(bookingId, buyerOrgId, patchedBooking, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the details of a single Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingRetrieve(bookingId: string, buyerOrgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingRetrieve(bookingId, buyerOrgId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of SMS\'s that have been sent from the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {string} [contact] contact
         * @param {string} [creator] creator
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {'CONFIRMATION' | 'REMINDER' | 'UPDATE'} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingSmsHistoryList(bookingId: string, buyerOrgId: string, contact?: string, creator?: string, page?: number, pageSize?: number, type?: 'CONFIRMATION' | 'REMINDER' | 'UPDATE', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedBookingSMSHistoryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingSmsHistoryList(bookingId, buyerOrgId, contact, creator, page, pageSize, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the Booking, returning the latest serialized data.  NOTE: the \'row\' still behaves the same as a `PUT` where by any fields not overwritten will still be present. This gives the system the ability to deactivate and re-active fields without losing data.  A Booking can only be edited in the following cases.  1. The Booking.status == `OPEN` 2. The Booking.status == `LOCKED` and Authenticated User is an admin.  If permission is denied the reason will be present in the `detail` key in the body.  See the `status` field on the Booking for more information. 
         * @summary Update the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {Booking} booking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingUpdate(bookingId: string, buyerOrgId: string, booking: Booking, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Booking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingUpdate(bookingId, buyerOrgId, booking, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationBookingApi - factory interface
 * @export
 */
export const OrganisationBookingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationBookingApiFp(configuration)
    return {
        /**
         * This can only occur when:Booking.status `CANCELLED`or Booking.status == `OPEN` AND There\'s no `ACCEPTED` Confirmations.  If there\'s `ACCEPTED` Confirmations, the Booking must be `CANCELLED` first.  See the `status` field on the Booking for more information.
         * @summary Archive the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingArchiveCreate(bookingId: string, buyerOrgId: string, options?: any): AxiosPromise<BookingConfig> {
            return localVarFp.orgBookingArchiveCreate(bookingId, buyerOrgId, options).then((request) => request(axios, basePath));
        },
        /**
         * An SMS is sent out to the recipient of the Confirmations alerting them it has been cancelled.  A cancellation can only occur when Booking.status == `OPEN` and it has at least one `ACCEPTED` Confirmation.  See the `status` field on the Booking for more information.
         * @summary Cancels the Booking and any `ACCEPTED` Confirmations with it
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingCancelCreate(bookingId: string, buyerOrgId: string, options?: any): AxiosPromise<BookingConfig> {
            return localVarFp.orgBookingCancelCreate(bookingId, buyerOrgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Outputs the configuration for the Bookings frontend
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfigRetrieve(buyerOrgId: string, options?: any): AxiosPromise<BookingConfig> {
            return localVarFp.orgBookingConfigRetrieve(buyerOrgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Booking
         * @param {string} buyerOrgId 
         * @param {Booking} booking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingCreate(buyerOrgId: string, booking: Booking, options?: any): AxiosPromise<Booking> {
            return localVarFp.orgBookingCreate(buyerOrgId, booking, options).then((request) => request(axios, basePath));
        },
        /**
         * The filters on this endpoint behave exactly the same as the listing
         * @summary Generates a CSV of all the Bookings which match the filter criteria.
         * @param {string} buyerOrgId 
         * @param {'CT' | 'SH' | 'GT'} product product
         * @param {string} [dateFrom] date_from
         * @param {string} [dateTo] date_to
         * @param {'bin' | 'json'} [format] 
         * @param {string} [includeArchived] include_archived
         * @param {string} [includeEmpty] include_empty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingCsvCreate(buyerOrgId: string, product: 'CT' | 'SH' | 'GT', dateFrom?: string, dateTo?: string, format?: 'bin' | 'json', includeArchived?: string, includeEmpty?: string, options?: any): AxiosPromise<any> {
            return localVarFp.orgBookingCsvCreate(buyerOrgId, product, dateFrom, dateTo, format, includeArchived, includeEmpty, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingDestroy(bookingId: string, buyerOrgId: string, options?: any): AxiosPromise<void> {
            return localVarFp.orgBookingDestroy(bookingId, buyerOrgId, options).then((request) => request(axios, basePath));
        },
        /**
         * This listing differs from most in that it is a dict instead of a list, where each booking is grouped together by their date.  ``` {     \"results\": {         \"2022-01-02\": {             \"counts\": {\"incoming\": 10, \"outgoing\": 500\"},             \"bookings\": [{booking1}, {booking2}],         },         \"2022-01-01\": {             \"counts\": {\"incoming\": 0, \"outgoing\": 10\"},             \"bookings\": [{booking3}],         }     }     \"report\": {} } ```
         * @summary Retrieve a list of all the Booking associated with the Organisation
         * @param {string} buyerOrgId 
         * @param {'CT' | 'SH' | 'GT'} product product
         * @param {string} [dateFrom] date_from
         * @param {string} [dateTo] date_to
         * @param {string} [includeArchived] include_archived
         * @param {string} [includeEmpty] include_empty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingList(buyerOrgId: string, product: 'CT' | 'SH' | 'GT', dateFrom?: string, dateTo?: string, includeArchived?: string, includeEmpty?: string, options?: any): AxiosPromise<BookingListing> {
            return localVarFp.orgBookingList(buyerOrgId, product, dateFrom, dateTo, includeArchived, includeEmpty, options).then((request) => request(axios, basePath));
        },
        /**
         * Locking can only occur when the status of the Booking == OPEN and the AuthenticatedUser is an admin  See the `status` field on the Booking for more information.
         * @summary Lock the Booking so only Admins can edit it. Admin access only
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingLockCreate(bookingId: string, buyerOrgId: string, options?: any): AxiosPromise<Booking> {
            return localVarFp.orgBookingLockCreate(bookingId, buyerOrgId, options).then((request) => request(axios, basePath));
        },
        /**
         * Unlocking can only occur when the status of the Booking == `LOCKED` and the Authenticated User is an                 admin\"  See the `status` field on the Booking for more information.
         * @summary Unlock the Booking. Admin access only
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingLockDestroy(bookingId: string, buyerOrgId: string, options?: any): AxiosPromise<Booking> {
            return localVarFp.orgBookingLockDestroy(bookingId, buyerOrgId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the fields passed through and returns the newly updated Booking. See \"update\" for more info
         * @summary Partially update the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {PatchedBooking} [patchedBooking] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingPartialUpdate(bookingId: string, buyerOrgId: string, patchedBooking?: PatchedBooking, options?: any): AxiosPromise<Booking> {
            return localVarFp.orgBookingPartialUpdate(bookingId, buyerOrgId, patchedBooking, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the details of a single Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingRetrieve(bookingId: string, buyerOrgId: string, options?: any): AxiosPromise<Booking> {
            return localVarFp.orgBookingRetrieve(bookingId, buyerOrgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of SMS\'s that have been sent from the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {string} [contact] contact
         * @param {string} [creator] creator
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {'CONFIRMATION' | 'REMINDER' | 'UPDATE'} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingSmsHistoryList(bookingId: string, buyerOrgId: string, contact?: string, creator?: string, page?: number, pageSize?: number, type?: 'CONFIRMATION' | 'REMINDER' | 'UPDATE', options?: any): AxiosPromise<PaginatedBookingSMSHistoryList> {
            return localVarFp.orgBookingSmsHistoryList(bookingId, buyerOrgId, contact, creator, page, pageSize, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the Booking, returning the latest serialized data.  NOTE: the \'row\' still behaves the same as a `PUT` where by any fields not overwritten will still be present. This gives the system the ability to deactivate and re-active fields without losing data.  A Booking can only be edited in the following cases.  1. The Booking.status == `OPEN` 2. The Booking.status == `LOCKED` and Authenticated User is an admin.  If permission is denied the reason will be present in the `detail` key in the body.  See the `status` field on the Booking for more information. 
         * @summary Update the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {Booking} booking 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingUpdate(bookingId: string, buyerOrgId: string, booking: Booking, options?: any): AxiosPromise<Booking> {
            return localVarFp.orgBookingUpdate(bookingId, buyerOrgId, booking, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for orgBookingArchiveCreate operation in OrganisationBookingApi.
 * @export
 * @interface OrganisationBookingApiOrgBookingArchiveCreateRequest
 */
export interface OrganisationBookingApiOrgBookingArchiveCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingArchiveCreate
     */
    readonly bookingId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingArchiveCreate
     */
    readonly buyerOrgId: string
}

/**
 * Request parameters for orgBookingCancelCreate operation in OrganisationBookingApi.
 * @export
 * @interface OrganisationBookingApiOrgBookingCancelCreateRequest
 */
export interface OrganisationBookingApiOrgBookingCancelCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingCancelCreate
     */
    readonly bookingId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingCancelCreate
     */
    readonly buyerOrgId: string
}

/**
 * Request parameters for orgBookingConfigRetrieve operation in OrganisationBookingApi.
 * @export
 * @interface OrganisationBookingApiOrgBookingConfigRetrieveRequest
 */
export interface OrganisationBookingApiOrgBookingConfigRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingConfigRetrieve
     */
    readonly buyerOrgId: string
}

/**
 * Request parameters for orgBookingCreate operation in OrganisationBookingApi.
 * @export
 * @interface OrganisationBookingApiOrgBookingCreateRequest
 */
export interface OrganisationBookingApiOrgBookingCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingCreate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {Booking}
     * @memberof OrganisationBookingApiOrgBookingCreate
     */
    readonly booking: Booking
}

/**
 * Request parameters for orgBookingCsvCreate operation in OrganisationBookingApi.
 * @export
 * @interface OrganisationBookingApiOrgBookingCsvCreateRequest
 */
export interface OrganisationBookingApiOrgBookingCsvCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingCsvCreate
     */
    readonly buyerOrgId: string

    /**
     * product
     * @type {'CT' | 'SH' | 'GT'}
     * @memberof OrganisationBookingApiOrgBookingCsvCreate
     */
    readonly product: 'CT' | 'SH' | 'GT'

    /**
     * date_from
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingCsvCreate
     */
    readonly dateFrom?: string

    /**
     * date_to
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingCsvCreate
     */
    readonly dateTo?: string

    /**
     * 
     * @type {'bin' | 'json'}
     * @memberof OrganisationBookingApiOrgBookingCsvCreate
     */
    readonly format?: 'bin' | 'json'

    /**
     * include_archived
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingCsvCreate
     */
    readonly includeArchived?: string

    /**
     * include_empty
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingCsvCreate
     */
    readonly includeEmpty?: string
}

/**
 * Request parameters for orgBookingDestroy operation in OrganisationBookingApi.
 * @export
 * @interface OrganisationBookingApiOrgBookingDestroyRequest
 */
export interface OrganisationBookingApiOrgBookingDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingDestroy
     */
    readonly bookingId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingDestroy
     */
    readonly buyerOrgId: string
}

/**
 * Request parameters for orgBookingList operation in OrganisationBookingApi.
 * @export
 * @interface OrganisationBookingApiOrgBookingListRequest
 */
export interface OrganisationBookingApiOrgBookingListRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingList
     */
    readonly buyerOrgId: string

    /**
     * product
     * @type {'CT' | 'SH' | 'GT'}
     * @memberof OrganisationBookingApiOrgBookingList
     */
    readonly product: 'CT' | 'SH' | 'GT'

    /**
     * date_from
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingList
     */
    readonly dateFrom?: string

    /**
     * date_to
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingList
     */
    readonly dateTo?: string

    /**
     * include_archived
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingList
     */
    readonly includeArchived?: string

    /**
     * include_empty
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingList
     */
    readonly includeEmpty?: string
}

/**
 * Request parameters for orgBookingLockCreate operation in OrganisationBookingApi.
 * @export
 * @interface OrganisationBookingApiOrgBookingLockCreateRequest
 */
export interface OrganisationBookingApiOrgBookingLockCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingLockCreate
     */
    readonly bookingId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingLockCreate
     */
    readonly buyerOrgId: string
}

/**
 * Request parameters for orgBookingLockDestroy operation in OrganisationBookingApi.
 * @export
 * @interface OrganisationBookingApiOrgBookingLockDestroyRequest
 */
export interface OrganisationBookingApiOrgBookingLockDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingLockDestroy
     */
    readonly bookingId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingLockDestroy
     */
    readonly buyerOrgId: string
}

/**
 * Request parameters for orgBookingPartialUpdate operation in OrganisationBookingApi.
 * @export
 * @interface OrganisationBookingApiOrgBookingPartialUpdateRequest
 */
export interface OrganisationBookingApiOrgBookingPartialUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingPartialUpdate
     */
    readonly bookingId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingPartialUpdate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {PatchedBooking}
     * @memberof OrganisationBookingApiOrgBookingPartialUpdate
     */
    readonly patchedBooking?: PatchedBooking
}

/**
 * Request parameters for orgBookingRetrieve operation in OrganisationBookingApi.
 * @export
 * @interface OrganisationBookingApiOrgBookingRetrieveRequest
 */
export interface OrganisationBookingApiOrgBookingRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingRetrieve
     */
    readonly bookingId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingRetrieve
     */
    readonly buyerOrgId: string
}

/**
 * Request parameters for orgBookingSmsHistoryList operation in OrganisationBookingApi.
 * @export
 * @interface OrganisationBookingApiOrgBookingSmsHistoryListRequest
 */
export interface OrganisationBookingApiOrgBookingSmsHistoryListRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingSmsHistoryList
     */
    readonly bookingId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingSmsHistoryList
     */
    readonly buyerOrgId: string

    /**
     * contact
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingSmsHistoryList
     */
    readonly contact?: string

    /**
     * creator
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingSmsHistoryList
     */
    readonly creator?: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof OrganisationBookingApiOrgBookingSmsHistoryList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrganisationBookingApiOrgBookingSmsHistoryList
     */
    readonly pageSize?: number

    /**
     * type
     * @type {'CONFIRMATION' | 'REMINDER' | 'UPDATE'}
     * @memberof OrganisationBookingApiOrgBookingSmsHistoryList
     */
    readonly type?: 'CONFIRMATION' | 'REMINDER' | 'UPDATE'
}

/**
 * Request parameters for orgBookingUpdate operation in OrganisationBookingApi.
 * @export
 * @interface OrganisationBookingApiOrgBookingUpdateRequest
 */
export interface OrganisationBookingApiOrgBookingUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingUpdate
     */
    readonly bookingId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingApiOrgBookingUpdate
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {Booking}
     * @memberof OrganisationBookingApiOrgBookingUpdate
     */
    readonly booking: Booking
}

/**
 * OrganisationBookingApi - object-oriented interface
 * @export
 * @class OrganisationBookingApi
 * @extends {BaseAPI}
 */
export class OrganisationBookingApi extends BaseAPI {
    /**
     * This can only occur when:Booking.status `CANCELLED`or Booking.status == `OPEN` AND There\'s no `ACCEPTED` Confirmations.  If there\'s `ACCEPTED` Confirmations, the Booking must be `CANCELLED` first.  See the `status` field on the Booking for more information.
     * @summary Archive the Booking
     * @param {OrganisationBookingApiOrgBookingArchiveCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingApi
     */
    public orgBookingArchiveCreate(requestParameters: OrganisationBookingApiOrgBookingArchiveCreateRequest, options?: any) {
        return OrganisationBookingApiFp(this.configuration).orgBookingArchiveCreate(requestParameters.bookingId, requestParameters.buyerOrgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * An SMS is sent out to the recipient of the Confirmations alerting them it has been cancelled.  A cancellation can only occur when Booking.status == `OPEN` and it has at least one `ACCEPTED` Confirmation.  See the `status` field on the Booking for more information.
     * @summary Cancels the Booking and any `ACCEPTED` Confirmations with it
     * @param {OrganisationBookingApiOrgBookingCancelCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingApi
     */
    public orgBookingCancelCreate(requestParameters: OrganisationBookingApiOrgBookingCancelCreateRequest, options?: any) {
        return OrganisationBookingApiFp(this.configuration).orgBookingCancelCreate(requestParameters.bookingId, requestParameters.buyerOrgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Outputs the configuration for the Bookings frontend
     * @param {OrganisationBookingApiOrgBookingConfigRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingApi
     */
    public orgBookingConfigRetrieve(requestParameters: OrganisationBookingApiOrgBookingConfigRetrieveRequest, options?: any) {
        return OrganisationBookingApiFp(this.configuration).orgBookingConfigRetrieve(requestParameters.buyerOrgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a Booking
     * @param {OrganisationBookingApiOrgBookingCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingApi
     */
    public orgBookingCreate(requestParameters: OrganisationBookingApiOrgBookingCreateRequest, options?: any) {
        return OrganisationBookingApiFp(this.configuration).orgBookingCreate(requestParameters.buyerOrgId, requestParameters.booking, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The filters on this endpoint behave exactly the same as the listing
     * @summary Generates a CSV of all the Bookings which match the filter criteria.
     * @param {OrganisationBookingApiOrgBookingCsvCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingApi
     */
    public orgBookingCsvCreate(requestParameters: OrganisationBookingApiOrgBookingCsvCreateRequest, options?: any) {
        return OrganisationBookingApiFp(this.configuration).orgBookingCsvCreate(requestParameters.buyerOrgId, requestParameters.product, requestParameters.dateFrom, requestParameters.dateTo, requestParameters.format, requestParameters.includeArchived, requestParameters.includeEmpty, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Booking
     * @param {OrganisationBookingApiOrgBookingDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingApi
     */
    public orgBookingDestroy(requestParameters: OrganisationBookingApiOrgBookingDestroyRequest, options?: any) {
        return OrganisationBookingApiFp(this.configuration).orgBookingDestroy(requestParameters.bookingId, requestParameters.buyerOrgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This listing differs from most in that it is a dict instead of a list, where each booking is grouped together by their date.  ``` {     \"results\": {         \"2022-01-02\": {             \"counts\": {\"incoming\": 10, \"outgoing\": 500\"},             \"bookings\": [{booking1}, {booking2}],         },         \"2022-01-01\": {             \"counts\": {\"incoming\": 0, \"outgoing\": 10\"},             \"bookings\": [{booking3}],         }     }     \"report\": {} } ```
     * @summary Retrieve a list of all the Booking associated with the Organisation
     * @param {OrganisationBookingApiOrgBookingListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingApi
     */
    public orgBookingList(requestParameters: OrganisationBookingApiOrgBookingListRequest, options?: any) {
        return OrganisationBookingApiFp(this.configuration).orgBookingList(requestParameters.buyerOrgId, requestParameters.product, requestParameters.dateFrom, requestParameters.dateTo, requestParameters.includeArchived, requestParameters.includeEmpty, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Locking can only occur when the status of the Booking == OPEN and the AuthenticatedUser is an admin  See the `status` field on the Booking for more information.
     * @summary Lock the Booking so only Admins can edit it. Admin access only
     * @param {OrganisationBookingApiOrgBookingLockCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingApi
     */
    public orgBookingLockCreate(requestParameters: OrganisationBookingApiOrgBookingLockCreateRequest, options?: any) {
        return OrganisationBookingApiFp(this.configuration).orgBookingLockCreate(requestParameters.bookingId, requestParameters.buyerOrgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unlocking can only occur when the status of the Booking == `LOCKED` and the Authenticated User is an                 admin\"  See the `status` field on the Booking for more information.
     * @summary Unlock the Booking. Admin access only
     * @param {OrganisationBookingApiOrgBookingLockDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingApi
     */
    public orgBookingLockDestroy(requestParameters: OrganisationBookingApiOrgBookingLockDestroyRequest, options?: any) {
        return OrganisationBookingApiFp(this.configuration).orgBookingLockDestroy(requestParameters.bookingId, requestParameters.buyerOrgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the fields passed through and returns the newly updated Booking. See \"update\" for more info
     * @summary Partially update the Booking
     * @param {OrganisationBookingApiOrgBookingPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingApi
     */
    public orgBookingPartialUpdate(requestParameters: OrganisationBookingApiOrgBookingPartialUpdateRequest, options?: any) {
        return OrganisationBookingApiFp(this.configuration).orgBookingPartialUpdate(requestParameters.bookingId, requestParameters.buyerOrgId, requestParameters.patchedBooking, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the details of a single Booking
     * @param {OrganisationBookingApiOrgBookingRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingApi
     */
    public orgBookingRetrieve(requestParameters: OrganisationBookingApiOrgBookingRetrieveRequest, options?: any) {
        return OrganisationBookingApiFp(this.configuration).orgBookingRetrieve(requestParameters.bookingId, requestParameters.buyerOrgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of SMS\'s that have been sent from the Booking
     * @param {OrganisationBookingApiOrgBookingSmsHistoryListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingApi
     */
    public orgBookingSmsHistoryList(requestParameters: OrganisationBookingApiOrgBookingSmsHistoryListRequest, options?: any) {
        return OrganisationBookingApiFp(this.configuration).orgBookingSmsHistoryList(requestParameters.bookingId, requestParameters.buyerOrgId, requestParameters.contact, requestParameters.creator, requestParameters.page, requestParameters.pageSize, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the Booking, returning the latest serialized data.  NOTE: the \'row\' still behaves the same as a `PUT` where by any fields not overwritten will still be present. This gives the system the ability to deactivate and re-active fields without losing data.  A Booking can only be edited in the following cases.  1. The Booking.status == `OPEN` 2. The Booking.status == `LOCKED` and Authenticated User is an admin.  If permission is denied the reason will be present in the `detail` key in the body.  See the `status` field on the Booking for more information. 
     * @summary Update the Booking
     * @param {OrganisationBookingApiOrgBookingUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingApi
     */
    public orgBookingUpdate(requestParameters: OrganisationBookingApiOrgBookingUpdateRequest, options?: any) {
        return OrganisationBookingApiFp(this.configuration).orgBookingUpdate(requestParameters.bookingId, requestParameters.buyerOrgId, requestParameters.booking, options).then((request) => request(this.axios, this.basePath));
    }
}
