/* tslint:disable */
/* eslint-disable */
/**
 * Agora Livestock API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0 (v2)
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { APIException } from '../model';
// @ts-ignore
import { BookingConfirmationIncomingWithBooking } from '../model';
// @ts-ignore
import { PaginatedBookingConfirmationWithBookingList } from '../model';
/**
 * OrganisationBookingConfirmationIncomingApi - axios parameter creator
 * @export
 */
export const OrganisationBookingConfirmationIncomingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve a list of all the incoming Confirmations associated with the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationIncomingList: async (bookingId: string, buyerOrgId: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('orgBookingConfirmationIncomingList', 'bookingId', bookingId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingConfirmationIncomingList', 'buyerOrgId', buyerOrgId)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/{booking_id}/confirmation/incoming`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the details of a single incoming Confirmation
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {string} incomingConfirmationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationIncomingRetrieve: async (bookingId: string, buyerOrgId: string, incomingConfirmationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookingId' is not null or undefined
            assertParamExists('orgBookingConfirmationIncomingRetrieve', 'bookingId', bookingId)
            // verify required parameter 'buyerOrgId' is not null or undefined
            assertParamExists('orgBookingConfirmationIncomingRetrieve', 'buyerOrgId', buyerOrgId)
            // verify required parameter 'incomingConfirmationId' is not null or undefined
            assertParamExists('orgBookingConfirmationIncomingRetrieve', 'incomingConfirmationId', incomingConfirmationId)
            const localVarPath = `/v2/org/{buyer_org_id}/booking/{booking_id}/confirmation/incoming/{incoming_confirmation_id}`
                .replace(`{${"booking_id"}}`, encodeURIComponent(String(bookingId)))
                .replace(`{${"buyer_org_id"}}`, encodeURIComponent(String(buyerOrgId)))
                .replace(`{${"incoming_confirmation_id"}}`, encodeURIComponent(String(incomingConfirmationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationBookingConfirmationIncomingApi - functional programming interface
 * @export
 */
export const OrganisationBookingConfirmationIncomingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationBookingConfirmationIncomingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve a list of all the incoming Confirmations associated with the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingConfirmationIncomingList(bookingId: string, buyerOrgId: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedBookingConfirmationWithBookingList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingConfirmationIncomingList(bookingId, buyerOrgId, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the details of a single incoming Confirmation
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {string} incomingConfirmationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orgBookingConfirmationIncomingRetrieve(bookingId: string, buyerOrgId: string, incomingConfirmationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookingConfirmationIncomingWithBooking>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orgBookingConfirmationIncomingRetrieve(bookingId, buyerOrgId, incomingConfirmationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationBookingConfirmationIncomingApi - factory interface
 * @export
 */
export const OrganisationBookingConfirmationIncomingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationBookingConfirmationIncomingApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve a list of all the incoming Confirmations associated with the Booking
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationIncomingList(bookingId: string, buyerOrgId: string, page?: number, pageSize?: number, options?: any): AxiosPromise<PaginatedBookingConfirmationWithBookingList> {
            return localVarFp.orgBookingConfirmationIncomingList(bookingId, buyerOrgId, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the details of a single incoming Confirmation
         * @param {string} bookingId 
         * @param {string} buyerOrgId 
         * @param {string} incomingConfirmationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orgBookingConfirmationIncomingRetrieve(bookingId: string, buyerOrgId: string, incomingConfirmationId: string, options?: any): AxiosPromise<BookingConfirmationIncomingWithBooking> {
            return localVarFp.orgBookingConfirmationIncomingRetrieve(bookingId, buyerOrgId, incomingConfirmationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for orgBookingConfirmationIncomingList operation in OrganisationBookingConfirmationIncomingApi.
 * @export
 * @interface OrganisationBookingConfirmationIncomingApiOrgBookingConfirmationIncomingListRequest
 */
export interface OrganisationBookingConfirmationIncomingApiOrgBookingConfirmationIncomingListRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationIncomingApiOrgBookingConfirmationIncomingList
     */
    readonly bookingId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationIncomingApiOrgBookingConfirmationIncomingList
     */
    readonly buyerOrgId: string

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof OrganisationBookingConfirmationIncomingApiOrgBookingConfirmationIncomingList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof OrganisationBookingConfirmationIncomingApiOrgBookingConfirmationIncomingList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for orgBookingConfirmationIncomingRetrieve operation in OrganisationBookingConfirmationIncomingApi.
 * @export
 * @interface OrganisationBookingConfirmationIncomingApiOrgBookingConfirmationIncomingRetrieveRequest
 */
export interface OrganisationBookingConfirmationIncomingApiOrgBookingConfirmationIncomingRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationIncomingApiOrgBookingConfirmationIncomingRetrieve
     */
    readonly bookingId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationIncomingApiOrgBookingConfirmationIncomingRetrieve
     */
    readonly buyerOrgId: string

    /**
     * 
     * @type {string}
     * @memberof OrganisationBookingConfirmationIncomingApiOrgBookingConfirmationIncomingRetrieve
     */
    readonly incomingConfirmationId: string
}

/**
 * OrganisationBookingConfirmationIncomingApi - object-oriented interface
 * @export
 * @class OrganisationBookingConfirmationIncomingApi
 * @extends {BaseAPI}
 */
export class OrganisationBookingConfirmationIncomingApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve a list of all the incoming Confirmations associated with the Booking
     * @param {OrganisationBookingConfirmationIncomingApiOrgBookingConfirmationIncomingListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingConfirmationIncomingApi
     */
    public orgBookingConfirmationIncomingList(requestParameters: OrganisationBookingConfirmationIncomingApiOrgBookingConfirmationIncomingListRequest, options?: any) {
        return OrganisationBookingConfirmationIncomingApiFp(this.configuration).orgBookingConfirmationIncomingList(requestParameters.bookingId, requestParameters.buyerOrgId, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the details of a single incoming Confirmation
     * @param {OrganisationBookingConfirmationIncomingApiOrgBookingConfirmationIncomingRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationBookingConfirmationIncomingApi
     */
    public orgBookingConfirmationIncomingRetrieve(requestParameters: OrganisationBookingConfirmationIncomingApiOrgBookingConfirmationIncomingRetrieveRequest, options?: any) {
        return OrganisationBookingConfirmationIncomingApiFp(this.configuration).orgBookingConfirmationIncomingRetrieve(requestParameters.bookingId, requestParameters.buyerOrgId, requestParameters.incomingConfirmationId, options).then((request) => request(this.axios, this.basePath));
    }
}
